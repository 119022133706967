//import colors from 'vuetify/es5/util/colors'

//color palette: http://paletton.com/#uid=33w0u0kuos-jk-4osuWvNmNCRhK

import './main.styl'

const theme = {
  primary: '#0072b5',
  secondary: '#9bb7d4',
  accent: '#FDAc53',
  success: '#089D59',
  error: '#8D2600'
  // All keys will generate theme styles,
  // Here we add a custom `tertiary` color
  //tertiary: colors.pink.base
}

export default theme;
