
<template>
  <v-list-tile-sub-title v-if="isLoaded" class="navbar">
    <v-toolbar v-if="!forceDesktop" style="background-color: unset;" class="hidden-md-and-up" flat dense>
      <v-toolbar-title class="ml-0">{{currentPageDisplay}}</v-toolbar-title>
      <v-toolbar-items>
        <v-btn icon @click.stop="mobileMenu=!mobileMenu" nudge-left>
          <v-icon>menu</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-navigation-drawer v-model="mobileMenu"
                         right
                         absolute
                         temporary
                         v-if="!forceDesktop">
      <v-list>
        <v-list-group v-for="(rootNav,pi) in navItems" :key="pi" :append-icon="rootNav.items?'$vuetify.icons.expand':''" v-if="canSee(rootNav)">
          <v-list-tile slot="activator">
            <v-list-tile-title @click="navigate(rootNav,true)">{{rootNav.display}}</v-list-tile-title>
          </v-list-tile>
          <v-list-tile class="header-item--sub"
                       v-for="(subnav, i) in rootNav.items"
                       :key="i"
                       v-if="canSee(subnav)">
            <v-list-tile-content>
              <v-list-tile-title @click="navigate(subnav)"><span class="header-item--sub--display">{{ subnav.display }}</span></v-list-tile-title>
              <v-list-tile-sub-title class="list-nav--subsub" v-if="subnav.items">
                <ul>
                  <li v-if="canSee(subsubnav)" v-for="(subsubnav, subsubindex) in subnav.items" :key="subsubindex">
                    <a @click="navigate(subsubnav)">
                      <span class="header-item--sub--display">{{subsubnav.display}}</span>
                    </a>
                  </li>
                </ul>
              </v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list-group>
      </v-list>
    </v-navigation-drawer>
    <v-toolbar style="background-color: unset;" :class="{'hidden-sm-and-down':'!forceDesktop'}" flat dense>
      <v-toolbar-items>
        <v-menu open-on-hover bottom offset-y transition="scale-transition"
                v-for="(rootNav,pi) in navItems" :key="pi" class="nav-menu-item" v-if="canSee(rootNav)">
          <v-btn slot="activator"
                 flat @click="navigate(rootNav)">{{rootNav.display}}</v-btn>
          <v-list class="list-nav--sub" v-if="rootNav.items && rootNav.items.length>0">
            <v-list-tile class="header-item--sub"
                         v-for="(subnav, index) in rootNav.items"
                         :key="index"
                         v-if="canSee(subnav)">
              <v-list-tile-content>
                <v-list-tile-title @click="navigate(subnav)"><span class="header-item--sub--display">{{ subnav.display }}</span></v-list-tile-title>
                <v-list-tile-sub-title class="list-nav--subsub" v-if="subnav.items">
                  <ul>
                    <li v-if="canSee(subsubnav)" v-for="(subsubnav, subsubindex) in subnav.items" :key="subsubindex">
                      <a @click="navigate(subsubnav)">
                        <span class="header-item--sub--display">{{subsubnav.display}}</span>
                      </a>
                    </li>
                  </ul>
                </v-list-tile-sub-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar-items>
    </v-toolbar>
    <p v-if="!removeSpacing"></p>
  </v-list-tile-sub-title>
</template>
<script>
//import eventBus from '../../utility/eventbus';

  import logger from '../../utility/logger';
  import fetchWrapper from '../../utility/fetch';

 export default {
    props: ['page', 'role', 'removeSpacing', 'navigationItems','forceDesktop'],
    data: () => ({
      isLoaded: false,
      isLoggedIn: false,
      navItems: [],
      mobileMenu:null,
      selectedParent: '',
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      access: '0'
    }),
  async mounted() {
    this.access = this.role || '0';
    var response = await fetchWrapper.get(`/api/content/navigation`);
    this.navItems = response.data.items;
    this.isLoaded = true;
  },
  computed: {
    currentPageDisplay() {
      for(var i=0;i<this.navItems.length;i++) {
        const navItem = this.navItems[i];
        if(navItem && navItem.value===this.page) return navItem.display;

        if(navItem && navItem.items) {
          for(var k=0;k<navItem.items.length;k++) {
            const subNavItem = navItem.items[k];
            if(subNavItem && subNavItem.value===this.page) return subNavItem.display;
          }
        }

      }
      return '';
    }
  },
  methods: {
    navigate: function(navItem,isMobile) {
      if(isMobile && navItem.items) return;
      if(!isMobile && navItem.items && !navItem.route) {
        this.toggleSubNav(navItem.value);
        return;
      }
      window.location = navItem.route;
    },
    toggleSubNav: function(parentName) {
      this.selectedParent = parentName;
    },
    canSee: function (navItem) {
      if (!navItem) return false;
      var role = parseInt(this.access);
      //console.log(`role: ${role}`)
      //console.log(`navItem: ${JSON.stringify(navItem)}`)
      //console.log(`access: ${(navItem.access & role) === role}`)
      return navItem.access === 0 || (role > 0 && (navItem.access & role) === role); //(this.isLoggedIn && (!navItem.isAdmin || this.selectedParent === 'admin'));
    }
  },
  watch: {
    role() {
      this.access = this.role || '0';
    }
    }
}
</script>
<style>
#toolbarTitle:hover {
  cursor: pointer;
}
.header-item--sub {
  font-size: .9em;
  height: auto;
}
.header-item--sub div.v-list__tile {
  height: auto;
}
.navbar .v-btn__content {
  text-transform: capitalize;
  font-weight: 300;
  font-size: large;
}
div.v-list__tile__content {
  margin: .25em 0 .25em 0;
  cursor: pointer;
}

  div.v-list__tile__content:hover {
    font-size: larger;
  }
  .header-item--sub--display:hover {
    font-weight: bolder;
  }

  .list-nav--subsub {
    font-size: small;
    margin-left: .5em;
  }
  .list-nav--subsub a {
    text-decoration: none;
  }
</style>
