<template>
  <v-layout :row="!isMobile" :column="isMobile" :align-center="!isMobile" style="min-height: fit-content">
    <v-flex :class="{'ml-4':!isMobile,'ml-1':isMobile}">
      <v-layout row>
        <v-flex>
          <div class="header-logo">
            <a href="/"><img src="../../../content/logo-wmahie.png" /></a>
            <!--<span>DRUG ADDICTION<br>RECOVERY TEAM</span>-->
          </div>
        </v-flex>
        <v-flex v-if="isMobile">
          <v-btn round light color="white" style="margin-top: 15px" class="mx-3 lighten-1" @click.native="executeLogFunction()">{{sessionAction}}</v-btn>
          <v-icon small @click="helpClick">help</v-icon>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-flex :class="{'mr-4':!isMobile,'mr-1':isMobile}">
      <!-- user info + login + nav-->
      <v-layout column>
        <v-flex :class="{'align-self-end':!isMobile}">
          <v-layout row>
            <v-flex>
              <v-layout v-if="loggedInUserText" column>
                <v-flex mx-2>{{loggedInUserText}}</v-flex>
                <v-flex v-if="loggedInUserText" mx-2>You have <a href="/dashboard/message">{{messageCount}}</a> new messages</v-flex>
              </v-layout>
            </v-flex>
            <v-flex v-if="!isMobile">
              <v-btn round light color="white" style="margin-top: 15px" class="mx-3 lighten-1" @click.native="executeLogFunction()">{{sessionAction}}</v-btn>
              <v-icon @click="helpClick" style="vertical-align:middle;">help</v-icon>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex align-self-end>
          <c-navigation :page="pageName" :role="access" removeSpacing="true"></c-navigation>
        </v-flex>
      </v-layout>
    </v-flex>
    <v-dialog v-model="showLoginDialog" persistent :retain-focus="false" max-width="550px">
      <v-card>
        <v-card-title>
          <span class="headline">Log In</span>
        </v-card-title>
        <v-card-text v-if="!showForgotUserName && !showForgotPassword">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex v-if="showNoAccount" xs12>
                <p style="color:red">The User Name and Password provided did not match any accounts in our system.</p>
              </v-flex>
              <v-flex xs12>
                <v-text-field ref="username" label="User Name" required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field ref="password" label="Password" type="password" @keyup.enter="executeLogin()" required></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-text v-if="showForgotUserName">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <p>Submit your email address. If we find a match in our system, An email with your User Name will be sent.</p>
              </v-flex>
              <v-flex xs12>
                <v-text-field ref="email" label="Email Address" required></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-text v-if="showForgotPassword">
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <p>Submit your User Name. An email with instruction to change your password will be sent to you.</p>
              </v-flex>
              <v-flex xs12>
                <v-text-field ref="username_forgot" label="User Name" required></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-flex xs12 text-xs-center>
            <v-btn v-if="!showForgotUserName && !showForgotPassword" color="blue darken-1" style="font-size:10px;" flat @click.native="executeForgotUserName()">Forgot User Name</v-btn>
            <v-btn v-if="!showForgotUserName && !showForgotPassword" color="blue darken-1" style="font-size:10px;" flat @click.native="executeForgotPassword()">Forgot Password</v-btn>
            <v-btn color="blue darken-1" flat @click.native="close()">Close</v-btn>
            <v-btn v-if="!showForgotUserName && !showForgotPassword" color="blue darken-1" flat @click.native="executeLogin()">Login</v-btn>
            <v-btn v-if="showForgotUserName" color="blue darken-1" flat @click.native="submitForgotUserName()">Submit</v-btn>
            <v-btn v-if="showForgotPassword" color="blue darken-1" flat @click.native="submitForgotPassword()">Submit</v-btn>
          </v-flex>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showChangePassword" persistent :retain-focus="false" max-width="500px">
      <v-card>
        <v-card-text>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field ref="password_new" label="New Password" type="password" required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field ref="password_new_check" label="Re-enter New Password" type="password" required></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click.native="close()">Close</v-btn>
          <v-btn color="blue darken-1" flat @click.native="submitChangePassword()">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showRegisterDialog" persistent :retain-focus="false" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Welcome, {{ name }}!</span>
        </v-card-title>
        <v-card-text v-if="!showForgotUserName && !showForgotPassword">
          <h3>Please register to begin</h3>
          <v-container grid-list-md>
            <v-layout wrap>
              <v-flex xs12>
                <v-text-field ref="r_username" label="User Name" @blur.native="userNameCheck" required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field ref="r_password" label="Password" type="password" required></v-text-field>
              </v-flex>
              <v-flex xs12>
                <v-text-field ref="password2" label="Re-enter Password" type="password" required></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="!showForgotUserName && !showForgotPassword" color="blue darken-1" flat @click.native="executeRegister()">Register</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showTimeoutDialog" persistent :retain-focus="false" max-width="500px">
      <v-card>
        <v-card-title>
          <span class="headline">Your Login Has Timed Out</span>
        </v-card-title>
        <v-card-text>
        </v-card-text>
        <v-card-actions>
          <v-btn color="blue darken-1" flat @click.native="executeLogout()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" flat @click.native="executeLoginDialog">Login</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <c-loader></c-loader>
  </v-layout>
</template>
<script>

import ping from '../../utility/ping'
import NavComponent from "../navigation/navigation.vue";
//import eventBus from '../../utility/eventbus';
import storage from '../../utility/storage';
import LoaderComponent from '../loader/loader';
import url from '../../utility/url';
import Vue from 'vue'
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

  export default {
    components: {
      'c-loader': LoaderComponent,
      'c-navigation': NavComponent
    },
    props: ['page', 'showTimeout'],
    data: () => ({
      showLoginDialog: false,
      showForgotUserName: false,
      showForgotPassword: false,
      showChangePassord: false,
      showNoAccount: false,
      showRegisterDialog: false,
      showTimeoutDialog: false,
      name: '',
      guid: '',
      //loggedInUserText: '',
      isLoggedIn: false,
      access: '0',
      pageName: '',
      isLoggedIn: false
      //sessionAction: 'Login'
    }),
    computed: {
      isMobile() {
        return this.$vuetify.breakpoint.smAndDown;
      },
      messageCount() {
        return storage.get(storage.storageType.session, storage.storageKeys.count)
      },
      sessionAction() {
        return this.isLoggedIn ? 'Logout' : 'Login';
      },
      loggedInUserText() {
        return this.isLoggedIn ? 'Welcome, ' + storage.get(storage.storageType.session, storage.storageKeys.auth) + '!' : '';
      }
    },
    mounted() {
      //this.getRole();
      this.pageName = this.page || '';
      //this.showTimeoutDialog = this.showTimeout || false;
      //console.log(`this.isLoggedIn: ${this.isLoggedIn}`)
      if (storage.exists(storage.storageType.session, storage.storageKeys.auth)) {
        this.isLoggedIn = ping.isLoggedIn()
          .then(data => {
            return true
          })
          .catch(err => {
            storage.remove(storage.storageType.session, storage.storageKeys.auth);
            storage.remove(storage.storageType.session, storage.storageKeys.role);
            storage.remove(storage.storageType.session, storage.storageKeys.count);
            this.showTimeoutDialog = true
            return false
          });
      }
      this.name = url.params.getValue('name');
      this.guid = url.params.getValue('tokenguid');
      if (url.params.getValue('f') != null) {
        this.showChangePassword = true;
        this.$forceUpdate();
      } else if (url.params.getValue('reauth') != null) {
        this.executeLogout();
        //this.showNoAccount = false;
        this.showLoginDialog = true;
        //const self = this;
        //this.$nextTick(() => this.$refs.username.focus());
        //Vue.nextTick()
        //  .then(function () {
        //    self.$refs.username.focus());
        //  });
        //this.$forceUpdate();
      } else if (this.guid != null && this.guid !== '') {
        //const self = this;
        this.showRegisterDialog = true;
        //this.$nextTick(() => this.$refs.username.focus());
        //Vue.nextTick()
        //  .then(function () {
        //    console.log(self.$refs.username.focus());
        //  });
        //this.$forceUpdate();
      } else {
        this.isLoggedIn = storage.exists(storage.storageType.session, storage.storageKeys.auth);
        this.access = storage.get(storage.storageType.session, storage.storageKeys.role);
      }
    },
    watch: {
      page() {
        this.pageName = this.page || '';
      },
      showTimeout() {
        this.showTimeoutDialog = this.showTimeout || false;
      }
    },
    methods: {
      helpClick() {
        window.location = '/first/info/support';
      },
      /*getRole() {
        const self = this;
        fetch(window.appData.config.ServiceApiRoot + '/User.svc/role', {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json'
          },
          redirect: "follow",
          referrer: "no-referrer",
          credentials: 'include'
        }).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error('Request failed');
          }
        }).then(function (jsonData) {
          self.access = jsonData;
          self.isLoggedIn = true;
          self.sessionAction = 'Logout';
          self.loggedInUserText = 'Welcome, ' + storage.get(storage.storageType.session, storage.storageKeys.auth) + '!';
          return new Promise(function (resolve, reject) { resolve(jsonData); })
        });
      },*/
      close() {
        this.showLoginDialog = false;
        this.showForgotUserName = false;
        this.showForgotPassword = false;
        this.showChangePassword = false,
        this.showNoAccount = false;
      },
      executeLoginDialog() {
        this.showLoginDialog = true;
      },
      executeLogFunction() {
        if(this.isLoggedIn) {
          this.executeLogout();
          this.$forceUpdate();
          window.location = '/';
        } else {
          //this.showNoAccount = false;
          this.showLoginDialog = true;
          //let self = this;
          //Vue.nextTick()
          //  .then(function () {
          //    console.log(self.$refs.username.focus());
          //  });
          //this.$forceUpdate();
        }      
      },
      executeForgotUserName() {
        this.showForgotUserName = true;
      },
      async submitForgotUserName() {
        const response = await fetch(window.appData.config.ServiceApiRoot + '/Token.svc/validate/' + this.$refs.email.lazyValue, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }
        });
        this.showLoginDialog = false;
        this.showForgotUserName = false;
      },
      executeForgotPassword() {
        this.showForgotPassword = true;
      },
      async submitForgotPassword() {
        const response = await fetch(window.appData.config.ServiceApiRoot + '/Token.svc/forgot/' + this.$refs.username_forgot.lazyValue, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          }
        });
        this.showLoginDialog = false;
        this.showForgotPassword = false;
      },
      async submitChangePassword() {
        const data = {
          Token: url.params.getValue('f'),
          Password: this.$refs.password_new.lazyValue
        };
        const response = await fetch(window.appData.config.ServiceApiRoot + '/Token.svc/change', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          },
          body: JSON.stringify(data)
        });
        const json = await response.json();
        const isAuth = json["UserId"] > 0;
        this.isLoggedIn = isAuth;
        this.showLoginDialog = !isAuth;
        if (isAuth) {
          const name = json["Name"];
          const access = json["Role"];
          const messageCount = json["MessageCount"];
          //this.loggedInUserText = 'Welcome, ' + name + '!';
          //this.sessionAction = 'Logout';
          storage.set(storage.storageType.session, storage.storageKeys.auth, name);
          storage.set(storage.storageType.session, storage.storageKeys.role, access);
          storage.set(storage.storageType.session, storage.storageKeys.count, messageCount);
          //eventBus.emit(eventBus.events.login, { isLoggedIn: this.isLoggedIn });
          window.location = '/data';
        } else {
          this.showNoAccount = true;
        }
        this.showChangePassword = false;
      },
      executeLogout() {
        this.isLoggedIn = false;
        this.showTimeoutDialog = false;
        //this.loggedInUserText = '';
        //this.sessionAction = 'Login';
        storage.remove(storage.storageType.session, storage.storageKeys.auth);
        storage.remove(storage.storageType.session, storage.storageKeys.role);
        storage.remove(storage.storageType.session, storage.storageKeys.count);
        //eventBus.emit(eventBus.events.login, { isLoggedIn: this.isLoggedIn, role: '0' })
        this.$cookies.remove("ReadyAuth");
        if (window.location.href.indexOf('/dashboard') > -1
          || window.location.href.indexOf('/admin') > -1
          || window.location.href.indexOf('/data') > -1)
          window.location = '/'
        else
          window.location.reload()
      },
      async executeLogin() {
        const data = {
          UserName: this.$refs.username.lazyValue,
          Password: this.$refs.password.lazyValue,
        };
        const response = await fetch(window.appData.config.ServiceApiRoot + '/Token.svc/post', {
          method: 'POST',
          credentials: 'include',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json; charset=utf-8'
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          body: JSON.stringify(data)
        });
        const json = await response.json();
        const isAuth = json["UserId"] > 0;
        console.log(`isAuth: ${isAuth}`)
        this.isLoggedIn = isAuth;
        this.access = '0';
        this.showLoginDialog = !isAuth;
        if (isAuth) {
          const name = json["Name"];
          const access = json["Role"];
          const messageCount = json["MessageCount"];
          //this.loggedInUserText = 'Welcome, ' + name + '!';
          //this.sessionAction = 'Logout';
          storage.set(storage.storageType.session, storage.storageKeys.auth, name);
          storage.set(storage.storageType.session, storage.storageKeys.role, access);
          storage.set(storage.storageType.session, storage.storageKeys.count, messageCount);
          //this.isLoggedIn = true;
          //eventBus.emit(eventBus.events.login, { isLoggedIn: this.isLoggedIn, role: this.access });
          if (window.location.href.indexOf('/dashboard') === -1
            || window.location.href.indexOf('/admin') === -1
            || window.location.href.indexOf('/data') === -1)
            window.location = '/data'
          else
            window.location.reload()
        } else {
          this.showNoAccount = true;
        }

      },
      async userNameCheck() {
        const self = this;

        const response = await fetch(window.appData.config.ServiceApiRoot + '/Token.svc/get/available/' + this.$refs.r_username.lazyValue, {
          method: 'GET',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json'
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer" // no-referrer, *client
        });
        return await response.json();

        //return fetch(window.appData.config.ServiceApiRoot + '/Token.svc/get/available/' + this.$refs.username.lazyValue, {
        //  method: 'GET',
        //  mode: 'cors',
        //  cache: 'no-cache',
        //  headers: {
        //    'Content-Type': 'application/json; charset=utf-8',
        //    'Accept': 'application/json'
        //  },
        //  credentials: 'include'
        //}).then(function (response) {
        //  if (response.ok) {
        //    const responseData = response.json();
        //    console.log('data: ' + responseData);
        //    return responseData;
        //  } else {
        //    throw new Error('Submission failed');
        //  }
        //}).then(function (data) {
        //  self.userNameAvailable = data;
        //});
      },
      async executeRegister() {
        if (!this.userNameCheck()) {
          alert('not available');
          return;
        }
        if (this.$refs.r_password.lazyValue !== this.$refs.password2.lazyValue) {
          alert('passwords no match')
          return;
        }
        const data = {
          UserName: this.$refs.r_username.lazyValue,
          Password: this.$refs.r_password.lazyValue,
          Name: this.name,
          TokenGuid: this.guid
        };
        const response = await fetch(window.appData.config.ServiceApiRoot + '/Token.svc/post/identification', {
          method: 'POST',
          mode: 'cors',
          cache: 'no-cache',
          headers: {
            'Content-Type': 'application/json; charset=utf-8',
            'Accept': 'application/json'
          },
          redirect: "follow", // manual, *follow, error
          referrer: "no-referrer", // no-referrer, *client
          body: JSON.stringify(data),
          credentials: 'include'
        });
        const json = await response.json();
        const isAuth = json["UserId"] > 0;
        this.isLoggedIn = isAuth;
        this.showRegisterDialog = !isAuth;
        if (isAuth) {
          const name = json["Name"];
          const access = json["Role"];
          const messageCount = json["MessageCount"];
          this.loggedInUserText = 'Welcome, ' + name + '!';
          this.sessionAction = 'Logout';
          storage.set(storage.storageType.session, storage.storageKeys.auth, name);
          storage.set(storage.storageType.session, storage.storageKeys.role, access);
          storage.set(storage.storageType.session, storage.storageKeys.count, messageCount);
          //eventBus.emit(eventBus.events.login, { isLoggedIn: this.isLoggedIn });
          window.location = '/data';
        } else {
          this.showNoAccount = true;
        }
      }
    }
  }
</script>
<style>
body {
  font-size: 20px !important;
  font-weight: 300;
}
strong {
  font-weight: bold;
}
  .header-logo img {
    float: left;
    /*width: 100px;*/
    height: 100px;
  }

  .header-logo span {
    position: relative;
    top: 18px;
    left: 10px;
  }
</style>
