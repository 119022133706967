import Vue from 'vue';
const EventBus = new Vue();

export default {
  events: {
    login: 'eventLogin',
    loader: 'eventLoader',
    articleLoad: 'article-load',
    drugList: 'drugList',

    /*drugList2: 'drugList2',
    referralList: 'referralList',
    raceList: 'raceList',
    genderList: 'genderList',
    orientationList: 'orientationList',
    disablilityList: 'disablilityList',
    militaryList: 'militaryList',
    incarcerationList: 'incarcerationList',
    educationList: 'educationList',
    employmentList: 'employmentList',
    industryList: 'industryList',
    usedList: 'usedList',
    usageList: 'usageList'*/
  },
  emit: function(eventName,data) {
    EventBus.$emit(eventName,data);
  },
  listen: function(eventName,callback) {
    EventBus.$on(eventName,callback);
  }
}
