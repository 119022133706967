<template>
  <loading :active.sync="show"
    :is-full-page="fullPage" loader="bars" color="#153D54" height=100 width=100>
  </loading>
</template>
<script>
  // Import component
  import Loading from 'vue-loading-overlay';
  // Import stylesheet
  import 'vue-loading-overlay/dist/vue-loading.css';
  import eventbus from '../../utility/eventbus';
  export default {
    components: {
      'loading':Loading
    },
    data: () => ({
      show: false,
    }),
    mounted() {
      const vueModel = this;
      eventbus.listen(eventbus.events.loader,function(data){
        vueModel.show = data;
      })
    }
  }
</script>
