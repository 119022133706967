<template>
  <v-footer dark mt-1 height="auto" style="min-height:fit-content;" class="pt-1">
    <v-layout column>
      <!--<v-flex>
        <v-layout row wrap pt-2>
          <v-flex md4 offset-md2>
            <v-layout row wrap justify-end align-end>
              <v-flex xs3>
                <v-img :src="logo" height="100" width="100"></v-img>
              </v-flex>
              <v-flex xs7 style="font-size:small;">
                <div>CONTACT US</div>
                <div><a style="text-decoration:none; color:white;" href="tel:4135882335">(413) 588-2335</a></div>
                <div>
                  <a style="text-decoration:none; color:white;" href="mailto:dartma@northamptonma.gov">dartma@northamptonma.gov</a>
                </div>
                <div>
                  <v-layout row>
                    <v-flex xs1>
                      <v-btn flat small icon href="https://www.facebook.com/HampshireHOPE/" target="_blank">
                        <v-icon>mdi-facebook</v-icon>
                      </v-btn>                     
                    </v-flex>
                    <v-flex xs1 offset-md1>
                      <v-btn flat small icon href="https://www.facebook.com/HampshireHOPE/" target="_blank">
                        <v-icon>mdi-twitter</v-icon>
                      </v-btn>                      
                    </v-flex>
                    <v-flex xs1 offset-md1>
                      <v-btn flat small icon href="https://www.facebook.com/HampshireHOPE/" target="_blank">
                        <v-icon>mdi-youtube</v-icon>
                      </v-btn>  
                    </v-flex>
                    <v-flex xs1 offset-md1>
                      <v-btn flat small icon href="https://www.facebook.com/HampshireHOPE/" target="_blank">
                        <v-icon>mdi-instagram</v-icon>
                      </v-btn>                      
                    </v-flex>
                  </v-layout>
                </div>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex md5 offset-md1 mt-2>
            <v-layout row wrap>
              <v-flex xs5 offset-xs1 md3>
                <ul class="footer-links" style="list-style:none;">
                  <li><a href="#">Careers</a></li>
                  <li><a href="#">DART Helpline</a></li>
                  <li><a href="#">DART in the media</a></li>
                </ul>
              </v-flex>
              <v-flex xs6>
                <ul class="footer-links" style="list-style:none;">
                  <li><a href="#">Learn about DART</a></li>
                  <li><a href="#">Community Events</a></li>
                  <li><a href="http://eepurl.com/dx4Qp9">Subscribe to our Newsletter</a></li>
                </ul>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>-->
      <v-flex text-xs-center my-2>
        <small>&copy;{{year}} — <strong>Northampton</strong></small>
      </v-flex>
    </v-layout>
  </v-footer>
</template>
<script>
  const currentYear = (new Date()).getFullYear();
  export default {
    data: () => ({
      year: currentYear,
      logo: '../../../content/DARTv2outline_blackopacity.jpg'
    })
  }
</script>


<style scope="">
  .footer-links {
    list-style: none;
  }
  .footer-links li {
    margin: -10px;
  }
  .footer-links li a {
    color: white;
    text-decoration: none;
    font-size: small;
  }
  .narrow-desktop {
    padding: 2em 10em 0em 10em;
  }

  .narrow-mobile {
    padding: 2em 2em 0em 2em;
  }

  .card-bottom {
    border-bottom: thin solid;
    position: absolute;
    left: 20%;
    width: 60%;
    padding-top: .75em;
  }
</style>
