export default {
  async base(route, options) {
    const response = await fetch(route, options);
    const json = await response.json();

    if (response.ok) json.success = true;
    else json.error = response.statusText;

    return json;
  },
  async get(route) {
    const response = await fetch(route, {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json'
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      credentials: 'include'
    });
    const json = await response.json();
    return json;
  },
  async post(route,data) {
    const response = await fetch(route, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json'
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      body: JSON.stringify(data),
      credentials: 'include'
    });
    const json = await response.json();

    if(response.ok) json.success = true;
    else json.error = response.statusText;

    return json;
  },
  async delete(route) {
    const response = await fetch(route, {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        'Accept': 'application/json'
      },
      redirect: "follow", // manual, *follow, error
      referrer: "no-referrer", // no-referrer, *client
      credentials: 'include'
    });
    const json = await response.json();

    if(response.ok) json.success = true;
    else json.error = response.statusText;

    return json;
  },
}
