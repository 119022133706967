export default {
  isLoggedIn: function () {
    return new Promise((resolve, reject) => {
      fetch(window.appData.config.ServiceApiRoot + '/Ping.svc/Sapien', {
        method: 'GET',
        mode: 'cors',
        cache: 'no-cache',
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          'Accept': 'application/json'
        },
        credentials: 'include'
      })
      .then(function (response) {
        if (response.ok) {
          resolve();
        } else {
          reject();
        }
      })
      .catch(function (error) {
        reject(error);
      })
    });
  }
}
