export default {
  params: {
    getValue: (paramName,valueIfNull) => {
      const urlParams = new URLSearchParams(window.location.search);
      const paramValue = urlParams.get(paramName);
      if(valueIfNull && (!paramValue || paramValue==='null' || paramValue==='undefined')) return valueIfNull;
      return paramValue;
    }
  },
  route: {
    getLastValue: () => {
      const lastValue = new URL(window.location).pathname.split('/').pop();
      return lastValue;
    },
    pathContains: (text) => {
      const pathName = new URL(window.location).pathname
      return (pathName||'').toLowerCase().indexOf((text||''))>=0;
    }
  },
  getFormData: function(data) {
    const formData = new FormData();

    buildFormData(formData, data);
    formData.append('rawJSON',JSON.stringify(data));
    return formData;

    function buildFormData(formData, data, parentKey) {
      if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
          buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
      } else {
        const value = data == null ? '' : data;
        if(data instanceof File) formData.append(parentKey, value);
      }
    }
  }
}
