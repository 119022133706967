<template>
  <v-content>
    <v-container fluid>
      <v-layout column>
        <v-flex>
          <h2>Welcome to the Western MA Regional Health Information Exchange (HIE)</h2>
          <p>
            The HIE database allows for improved agility in understanding the scope and impact of the opioid overdose epidemic
            across the region. The Western MA region is comprised of 4 counties: Berkshire, Franklin, Hampden and Hampshire.
            The sources and kinds of data are diverse. Contributing partners include municipalities, healthcare providers, allies
            with shared initiatives, agencies and a host of other community sector leaders.
          </p>
        </v-flex>
        <v-flex>
          <iframe src="https://na01.safelinks.protection.outlook.com/?url=https%3A%2F%2Fntondpw.maps.arcgis.com%2Fapps%2Fdashboards%2F8311b498281a4274b23852b322c83431&data=04%7C01%7C%7Cbbb8727b8cd04af6ffc908d934bd9794%7C84df9e7fe9f640afb435aaaaaaaaaaaa%7C1%7C0%7C637598814092719267%7CUnknown%7CTWFpbGZsb3d8eyJWIjoiMC4wLjAwMDAiLCJQIjoiV2luMzIiLCJBTiI6Ik1haWwiLCJXVCI6Mn0%3D%7C1000&sdata=BexjDZ4Z6il%2BBIQpmyZkN%2FkNQsHPA4t25AtF1VUI0Nc%3D&reserved=0" width="100%" height="800"></iframe>
        </v-flex>
        <v-flex>
          <h2>Community partners in the Western MA Region:</h2>
          <ul>
            <li>
              <a href="https://boapc.org/">Berkshire Overdose Addiction Prevention Collaborative</a>
            </li>
            <li>
              <a href="https://www.opioidtaskforce.org/">Opioid Task Force of Franklin County And The North Quabbin Region</a>
            </li>

            <li>
              <a href="https://hampdenda.com/community-safety-and-outreach-unit/hampden-county-addiction-taskforce/">Hampden County Addiction Taskforce</a>
            </li>

            <li>
              <a href="https://www.hampshirehope.org/">Hampshire HOPE</a>
            </li>
          </ul>
          <br>
          <ul>
            <li>
              <a href="https://healingcommunitiesstudy.org/sites/massachusetts.html">HEALing Communities Study</a>
            </li>
          </ul>
        </v-flex>
      </v-layout>
    </v-container>
  </v-content>
</template>

<script>


export default {
  components: {
  },
  props: [],
  data() {
    return {
      content: {},
      isLoaded: false
    };
  },
  mounted() {
    this.isLoaded = true;
  },
    methods: {
  }
  };

</script>

<style scope="">
</style>
