const storageWrapper = {
  set: (storageType,key,value) => {
    const storage = getStorage(storageType);
    storage.setItem(key,value);
  },
  get: (storageType,key) => {
    const storage = getStorage(storageType);
    return storage.getItem(key);
  },
  exists: (storageType,key) => {
    const storage = getStorage(storageType);
    if(storage.getItem(key)) return true;
    return false;
  },
  remove: (storageType,key) => {
    const storage = getStorage(storageType);
    storage.removeItem(key);
  },
  clear: (storageType) => {
    const storage = getStorage(storageType);
    storage.clear();
  },
  storageType: {
    session: 'session',
    local: 'local'
  },
  storageKeys: {
    auth: 'nh-auth',
    count: 'nh-count'
  }
}

function getStorage(storageType) {
  if(storageType===storageWrapper.storageType.local) return localStorage;
  else return sessionStorage;
}

export default storageWrapper;
