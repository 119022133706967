<template>
  <v-app>
    <c-header page="about"></c-header>

    <c-homepage v-if="isLoaded"></c-homepage>
    <c-footer></c-footer>
  </v-app>
</template>

<script>
import logger from "../utility/logger";
import HeaderComponent from "../components/header/header.vue";
import NavComponent from "../components/navigation/navigation.vue";
import FooterComponent from "../components/footer/footer.vue";
import eventbus from "../utility/eventbus";
import HomePageComponent from './homepage-wmahie'

export default {
  name: "app-home",
  components: {
    "c-header": HeaderComponent,
    "c-navigation": NavComponent,
    "c-footer": FooterComponent,
    "c-homepage": HomePageComponent
  },
  data() {
    return {
      pageName: "home",
      content: {},
      isLoaded: false
    };
  },
  mounted() {
    eventbus.emit(eventbus.events.loader, true);
    this.isLoaded = true;
    eventbus.emit(eventbus.events.loader, false);
    //const vueModel = this;
    //fetch(`/api/content/home`, {
    //  method: "GET",
    //  mode: "cors",
    //  cache: "no-cache",
    //  headers: {
    //    "Content-Type": "application/json; charset=utf-8"
    //  },
    //  redirect: "follow", // manual, *follow, error
    //  referrer: "no-referrer" // no-referrer, *client
    //})
    //  .then(function(response) {
    //    logger.log(
    //      "admin/content/strategies.module/init-response",
    //      "response complete"
    //    );
    //    if (response.ok) {
    //      return response.json();
    //    } else {
    //      logger.log(
    //        "admin/content/strategies.module/init-response",
    //        "error calling for content",
    //        true
    //      );
    //      throw new Error("Request to get content failed");
    //    }
    //  })
    //  .then(function(jsonData) {
    //    vueModel.content = jsonData;
    //    vueModel.isLoaded = jsonData && jsonData.id && jsonData.id != 0;
    //    eventbus.emit(eventbus.events.loader, false);
    //  })
    //  .catch(function(e) {
    //    alert(e);
    //    eventbus.emit(eventbus.events.loader, false);
    //  });
  }
};
</script>

<style scope="">
</style>
