import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import theme from '../utility/theme'
import App from './App.vue'

Vue.use(Vuetify,{ theme })

new Vue({
  el: '#app-home',
  render: h => h(App)
})
